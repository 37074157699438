<template>
  <!-- https://www.figma.com/file/xa8FrgPPuqXTcsfZdtUNZP/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8C%D0%B5?type=design&node-id=1296-157872&mode=design&t=ZxiRTo7fIj7ITSH6-0 -->
  <div class="about-us">
    <div class="info">
      <div class="info__image" />
      <div class="info__text-container">
        <p class="heading">Принимайте решения о своём здоровье на основе достоверной информации</p>
        <p class="tagline">Мы проверили все факты и отвечаем за свои слова!</p>
      </div>
    </div>
    <div class="tile-container">
      <TileContainer
        :tiles="tiles"
        :enable-swiper="enableSwiper"
        @click-tile="sendAnalytics"
        @scroll-swiper="sendScrollAnalytics"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import TileContainer from '@/containers/TilesContainer/TileContainer.vue';
  import type { TTile } from '~/containers/TilesContainer/types';
  import doctor2Content from '~/assets/images/doctor-2.svg?raw';
  import articleHealth from '~/assets/images/article-health.svg?raw';
  import diagnosis from '~/assets/images/diagnosis.svg?raw';

  export default defineNuxtComponent({
    name: 'TheAboutUs',

    components: {
      TileContainer,
    },

    data() {
      return {
        tiles: [
          {
            title: '100+',
            text: 'врачей-рецензентов',
            link: '/health/eksperty/',
            alt: 'фото-докотора',
            image: doctor2Content,
          },
          {
            title: '5 000+',
            text: 'статей о здоровье',
            link: '/health/list/',
            alt: 'фото планшета для записей',
            image: articleHealth,
          },
          {
            title: 'О разделе',
            text: 'как мы работаем',
            link: '/health/o-proekte/',
            alt: 'иконка информации',
            image: diagnosis,
          },
        ],
        enableSwiper: false,
      };
    },

    mounted() {
      if (window.innerWidth < 768) {
        this.enableSwiper = true;
      }
    },

    methods: {
      sendAnalytics(tile: TTile) {
        this.$sendYandexMetrika({
          level1: 'Клик_О нас',
          level4: tile.link,
        });
      },

      sendScrollAnalytics() {
        this.$sendYandexMetrika({
          level1: 'Скролл_О нас',
          level6: '1',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .about-us {
    background-color: #fff;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    padding: 16px 0;
    @include tablet {
      width: 768px;
      max-width: 768px;
      padding: 24px;
      margin: 0 auto;
      border-top: 1px solid #e7e7e7;
    }
    @include big-tablet {
      width: 832px;
      max-width: 832px;
    }
    @include desktop {
      display: flex;
      width: 100%;
      max-width: none;
    }
  }

  .info {
    display: none;
    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__image {
      width: 91px;
      height: 78px;
      @include lazeikaHealth;
    }
    &__text-container {
      margin-left: 16px;
      font-family: $secondFont;
      font-weight: 600;
      word-wrap: break-word;
      .heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #000;
        width: 400px;
      }
      .tagline {
        margin-top: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--main-color);
      }
    }
  }
  .tile-container {
    width: 100%;
    @include desktop {
      width: auto;
      margin-left: 32px;
    }

    :deep() {
      .tiles {
        max-width: 640px;
        @include tablet {
          width: 100%;
          max-width: none;
        }
        @include desktop {
          gap: 12px;
        }
      }
      .tile {
        height: 78px;
        min-width: 40%;
        width: 40%;
        scroll-snap-align: center;

        @include tablet {
          min-width: 32%;
          width: 32%;
        }

        @include desktop {
          width: 208px;
        }

        .tile-item__image {
          color: var(--main-color);
          svg {
            height: 48px;
            width: 45px;
            @include tablet {
              height: 49px;
              width: 49px;
            }
          }
        }

        .svg-icon {
          mask-size: 48px;
          @include tablet {
            mask-size: 51px;
          }
        }

        .tile-item__text {
          width: 65%;
        }
      }
    }
  }
</style>
