<template>
  <DefaultLayout
    class="health-template"
    :class="computedClass"
    :style="`
      --preview-header-color: #0530a4;
      --main-color: #2962f9;
      --main-color-2: #2962F9;
      --hover-color: #264eb1;
      --hover-color-2: #264eb1;
      --bg-color-substrate: #f8f9fb;
      --bg-color-substrate-2: #E1EAFD;
      --bg-color-substrate-3: #F3F5FC;
    `"
  >
    <template #above-content>
      <TheAboutUs v-if="isHealthHome && !isErrorPage" />
    </template>

    <slot />
  </DefaultLayout>
</template>

<script lang="ts">
  import DefaultLayout from './default.vue';
  import type { MetaObject } from 'nuxt/schema';
  import TheAboutUs from '~/components/AboutUs/TheAboutUs.vue';

  export default defineNuxtComponent({
    name: 'HealthLayout',

    components: {
      DefaultLayout,
      TheAboutUs,
    },

    head(): MetaObject {
      return {
        meta: [
          {
            hid: 'theme-color',
            name: 'theme-color',
            content: '#2962f9',
          },
        ],
      };
    },

    computed: {
      isErrorPage(): boolean {
        return Boolean(useError().value);
      },
      isHealthHome(): boolean {
        return useRoute().name === 'health-home';
      },
      computedClass(): Record<string, boolean> {
        return {
          'health-service-container': useRoute().name === 'health-services',
        };
      },
    },
  });
</script>

<style lang="scss">
  .health-template {
    background-color: #f3f5fc;

    .lh-large-article-card__wrapper::before {
      top: 0;
      height: 100%;
    }
  }
</style>
